import { CouponType } from '@innedit/innedit';
import classnames from 'classnames';
import React, { FC } from 'react';

import ListItem from '../../../../../CMS/containers/Boutique/components/new/Item';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

const Item: FC<ListItemProps> = ({ className, doc, index, onClick }) => {
  const data = doc.data() as CouponType;

  if (!data) {
    return null;
  }

  return (
    <ListItem
      actions={{ tooltip: doc.id, type: 'tooltip' }}
      className={classnames(className)}
      contentClassName="flex flex-col"
      displayActionId={false}
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <strong>{data.name}</strong>
      <span>{data.code}</span>
    </ListItem>
  );
};

export default Item;
