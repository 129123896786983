import { CouponData } from '@innedit/innedit';
import React, { FC, SyntheticEvent } from 'react';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../utils/requireBoutique';
import CMSView from '../../../../../CMS/components/View/index';
import Form from '../../../../../CMS/containers/Boutique/components/new/Form';
import params from '../../params.json';

const BannerForm: FC<BoutiqueProps & { docId: string }> = ({
  boutique,
  docId,
  user,
}) => {
  const handlePreviousOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // TODO gérer cette fonction
  };

  const handleNextOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // TODO gérer cette fonction
  };

  return (
    <CMSView>
      <Form
        docId={docId}
        itemPathnamePrefix="/website/coupons/"
        model={new CouponData({ boutique, params })}
        name="coupon"
        nextOnClick={handleNextOnClick}
        previousOnClick={handlePreviousOnClick}
        user={user}
      />
    </CMSView>
  );
};

export default requireBoutique(BannerForm);
